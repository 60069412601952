<style>
  button {
    padding: 1em 2em;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid rgb(var(--white));
    color: rgb(var(--white));
    border-radius: 32px;
    font: inherit;
  }

  h1, p {
    margin-block: 0;
    margin-bottom: 1em;
  }

  a {color: white;}

  .app, .app-wrapper {
    background-color: rgb(var(--black));
    text-align: center;
  }

  .header {
    padding: 1em;
    text-align: center;
  }

  .hero {
    /* padding: 1em; */
  }
  .hero__title {
    text-shadow: 2px 2px 0 #424242;
    font-family: "Comfortaa", sans-serif;
  }
  .hero__img, .services__card__img {
    width: 100%;
    aspect-ratio: 16/9;
  }
  .hero__content {
    padding: 1em;
  }

  .about__img {
    width: 100%;
    aspect-ratio: 16/9;
  }
  .about__content {padding: 1em;}
  .about__content__list {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .services {padding: 1em;}
  .services__card {margin-bottom: 1em;}
  .services__card:last-child {margin-bottom: 0;}

  .footer {
    padding: 1em;
    background-color: rgb(var(--olive));
    text-align: center;
  }
  .footer__link {
    margin-bottom: 1em;
    display: block;
  }

  @media (min-width: 786px) {
    .header {
      padding: 1em 10%;
    }

    .hero {
      margin-bottom: 5em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .hero__img {
      width: 80%;
    }
    .hero__content {
      width: 50%;
    }

    .about {
      margin-bottom: 5em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .about__content {
      width: 50%;
    }

    .services {
      margin-bottom: 5em;
    }
    .services__cards {
      margin-top: 5em;
      display: flex;
    }
    .services__card {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .services__card__img {margin-bottom: 2em}
    .services__card__content {
      width: 75%;
    }
  }
</style>

<svelte:head>
  <style>
    :root {
      --black: 21, 21, 21;
      --white: 234, 234, 234;
      --olive: 71, 83, 33;
    }

    body {
      margin: 0;
      color: white;
      font-family: "Nunito", sans-serif;
    }
  </style>
</svelte:head>

<div class="app-wrapper">
  <div class="app">

    <div class="header">
      <div>
        <strong>HYBRID BATTERY TECHNOLOGY</strong>
      </div>
      <hr/>
    </div>

    <div class="hero">
      <img class="hero__img" src="assets/hero.jpg" alt="Hero"/>
      <div class="hero__content">
        <h1 class="hero__title">HYBRID BATTERY TECHNOLOGY</h1>
        <p>
          <a href="mailto:hibridnebaterije@yahoo.com">
            hibridnebaterije@yahoo.com
          </a>
        </p>
        <button>
          <a href="tel:+381668006643">KONTAKT</a>
        </button>
      </div>
    </div>

    <div class="about">
      <img class="about__img" src="assets/about.png" alt="About"/>
      <div class="about__content">
        <h1>O nama</h1>
        <p>
          Naša firma je osnovana u cilju pružanja najkvalitetnijih usluga
          dijagnostike i reparacije svih hibridnih i električnih baterija.
        </p>
        <div class="about__content__list">
          <ul>
            <li>Naše usluge pružamo uz pomoć najsavremenijih mašina</li>
            <li>Preko hiljadu zadovoljnih mušterija</li>
            <li>Najpovoljnije cene</li>
            <li>Stručna ekipa sa višegodišnjim iskustvom</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="services">
      <div class="services__cards">
        <div class="services__card">
          <h3>Hibridne baterije: sve što treba da znate</h3>
          <img class="services__card__img" src="assets/services_1.png" alt="Services 1"/>
          <div class="services__card__content">
            <p>
              Hibridno vozilo koristi i benzin i električnu energiju. Ova
              tehnologija je dramatično promenila izgled ekološki prihvatljivih
              opcija vožnje. Hibridna vozila su postala naprednija, ali i dalje
              ostaju u dostupnosti i pristupačnosti. Hibridni automobili nude
              najbolju alternativu automobilima sa motorima sa unutrašnjim
              sagorevanjem. Hibridna vozila premošćuju jaz između električnih i
              benzinskih pogona. Hibridna vozila nude očigledne pogodnosti kao
              što su elegantni elektronski merači i izuzetna ekonomičnost
              goriva, ali mnogi potrošači nisu svesni kako ovi motori rade i
              šta ih čini tako odličnim za životnu sredinu. Motori koji rade
              isključivo na struju efikasno ubrzavaju i proizvode maksimalnu
              snagu kada ubrzavaju sa uspostavljanja. Nažalost, ove baterije
              moraju biti velike i skupe da bi mogle da putuju na velike
              udaljenosti. Vozila na baterije imaju ograničen domet od samo 70
              do 250 milja, prema Consumer Reports. U kombinaciji sa gasnim
              motorom, električni motori postaju daleko funkcionalni.
              Proizvođači automobila mogu da instaliraju mali, visoko efikasan
              gasni motor da dopune snagu svog električnog kolege. Sa rezervnom
              snagom koja je dostupna iz motora sa unutrašnjim sagorevanjem,
              moguće je koristiti manji i pristupni električni motor.
              Kombinovani ova dva sistema nude najbolju efikasnost i pouzdanost.
              Nedostatak ovog podešavanja je što je celokupni dizajn i dalje
              skup zbog dvostrukih motora. Kao što je svaki pojedinačni motor
              manji, oba dodaju težinu vozila i zauzimaju značajnu količinu
              prostora.
            </p>
          </div>
        </div>

        <div class="services__card">
          <h3>Kako rade hibridne baterije</h3>
          <img class="services__card__img" src="assets/services_2.png" alt="Services 2"/>
          <div class="services__card__content">
            <p>
              Hibridna vozila kombinuju tehnologiju električnih automobila sa
              tehnologijom tradicionalnih automobila. Hibridno vozilo koristi
              12-voltnu olovnu bateriju i benzin kao tradicionalno vozilo, a
              istovremeno crpi energiju iz električnih baterija. Vozilo se može
              neprimetno prevoditi između izvora energije tako da vozač nije ni
              svestan prelaza. Električna baterija se puni kroz proces poznat
              kao regenerativno kočenje. Energija proizvedena kada vozač
              pritisne papučicu kočnice punu električnu bateriju. Prebacivanje
              između električne i gasne snage je ključ za izuzetnu energetsku
              efikasnost hibridnog vozila. Hibridna vozila su samo deo vremena
              na gas, što ih čini 20 do 35 procenata štedljivim od
              tradicionalnih vozila. Ovo takođe smanjuje emisije izduvnih
              gasova vozila, čineći hibridni automobil lakšim za životnu
              sredinu. Jedan od nedostataka hibridnih baterija je njen
              ograničen životni vek. Većina hibridnih baterija ima garanciju od
              osam godina ili 100.000 kilometara, ali neke otkazuju pre tog
              vremena. Hibridno vozilo ne može da radi bez hibridnih baterija,
              tako da vlasnici vozila moraju periodično da se ulažu u nove
              hibridne baterije, što može da skupi održavanje vozila. Na sreću,
              hibridna tehnologija se uvek razvija. Moderne baterije su otpore
              od svojih starijih kolega. Proizvođači trećih strana takođe
              stupaju na scenu, dajući vozačima više opcija kada dođe vreme za
              zamenu baterije. Ove hibridne baterije nezavisnih proizvođača
              često su jeftinije od onih koje su dostupne u prodavnici.
              Hibridne baterije imaju dve elektrode koje se nalaze u rastvoru
              elektrolita. Ove elektrode su odvojene polimernim filmom koji
              sprečava kratki spoj. Elektrode se premošćuju kada se uključi
              uređaj, u ovom slučaju hibridno vozilo. Vredi napomenuti da je
              baterija u hibridnom vozilu upravo baterija koja sadrži više
              ćelije koje rade zajedno da bi stvorile ogromno punjenje
              neophodno za napajanje automobila. U svakoj ćeliji baterije
              nalazi se pozitivna elektroda i negativna elektroda. Joni sa
              pozitivno naelektrisane elektrode kreće se ka negativnoj
              elektrodi. Tamo pozitivni joni prihvataju elektrone koje je dala
              negativna elektroda. Ovaj složeni proces stvaranja električnog
              naboja. Energija koju proizvodi hibridna baterija određuje
              električni dom za koji je vozilo sposobno. Dostupan snaga iz
              baterija u tom trenutku određuje ubrzanje vozila.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <h1>Kontakt informacije</h1>
      <a class="footer__link" href="https://goo.gl/maps/2S58nBEjnmoobLVC7" target="_blank">
        Petra Božovica bb, Niš, Srbija
      </a>
      <a class="footer__link" href="mailto:hibridnebaterije@yahoo.com" target="_blank">
        hibridnebaterije@yahoo.com
      </a>
      <a class="footer__link" href="tel:+381668006643" target="_blank">
        066 800 6643
      </a>
      <a class="footer__link" href="tel:+381641156942" target="_blank">
        064 115 6942
      </a>
      <div>
        ©{new Date().getFullYear()} HYBRID BATTERY TECHNOLOGY
      </div>
    </div>

  </div>
</div>
